
import { defineComponent, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElInput, FormInstance, FormRules, ElNotification } from 'element-plus'

import { apiErrorNotification } from '@/core/helpers'
import { routeNames } from '@/router'
import { authService } from '@/services'

export default defineComponent({
  name: 'ForgotView',

  components: { ElInput },

  setup() {
    const router = useRouter()

    const loading = ref(false)

    const formRef = ref<FormInstance>()
    const formValues = reactive({
      email: '',
    })
    const formValidationRules = reactive<FormRules>({
      email: [
        {
          required: true,
          message: 'Email address is required!',
          trigger: 'change',
        },
        {
          type: 'email',
          message: 'Invalid email address!',
          trigger: 'change',
        },
      ],
    })

    const onSubmit = async () => {
      const isFormValid = await formRef.value?.validate()
      if (!isFormValid) {
        return false
      }

      loading.value = true

      try {
        await authService.requestResetPassword(formValues)

        ElNotification({
          message:
            'We sent you a password reset link. Please check your email address!',
          type: 'success',
        })

        router.push({ name: routeNames.login })
      } catch (error) {
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    const toSignInView = () => {
      router.push({ name: routeNames.login })
    }

    return {
      formRef,
      formValues,
      formValidationRules,
      loading,
      onSubmit,
      toSignInView,
    }
  },
})
